import { Injectable, inject } from '@angular/core';
import { first, map } from 'rxjs';
import { CustomHttpClientService } from '../../core/services/custom-http-client.service';
import { User } from '../util/user.model';
import { authApis } from '../util/auth.apis';
import { CacheService } from '../../core/services/cache.service';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private customHttp = inject(CustomHttpClientService);
  private cacheService = inject(CacheService);

  private readonly CACHE_KEYS = {
    ORDERS: 'cached_orders',
  };

  getUserBookings() {
    return this.cacheService.getCachedData(this.CACHE_KEYS.ORDERS, () =>
      this.customHttp.get<User>(authApis.getUserBookings).pipe(
        first(),
        map((res: any) => {
          return res;
        })
      )
    );
  }
}
