import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DemoService } from './demo.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-demo',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss'],
})
export class DemoComponent implements OnInit {
  productId: string = '';
  isLoading: boolean = false;
  error: string | null = null;
  success: boolean = false;
  receiptData: string | null = null;
  transactionDetails: { TransactionId: string; TransactionOTP: string } | null =
    null;

  constructor(
    private demoService: DemoService,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.login();
  }

  private async login() {
    try {
      this.isLoading = true;
      this._cd.markForCheck();
      await this.demoService.login().toPromise();
    } catch (err) {
      this.error = 'Failed to login. Please try again.';
    } finally {
      this.isLoading = false;
      this._cd.markForCheck();
    }
  }

  async createTransaction() {
    if (!this.productId.trim()) {
      this.error = 'Please enter a Product ID';
      return;
    }

    try {
      this.isLoading = true;
      this.error = null;
      this.success = false;
      this.receiptData = null;
      this.transactionDetails = null;
      this._cd.markForCheck();

      const response = await this.demoService
        .createTransaction(this.productId)
        .toPromise();

      this.success = true;
      this.receiptData = response?.data.Receipt || null;
      this.transactionDetails = response?.data
        ? {
            TransactionId: response.data.TransactionId,
            TransactionOTP: response.data.TransactionOTP,
          }
        : null;
      this.productId = ''; // Reset input after success
      this._cd.markForCheck();
    } catch (err) {
      this.error =
        (err as any).error.message ||
        'Failed to create transaction. Please try again.';
      this._cd.markForCheck();
    } finally {
      this.isLoading = false;
      this._cd.markForCheck();
    }
  }

  downloadReceipt() {
    if (!this.receiptData) return;

    // Convert base64 to PDF and download
    const byteCharacters = atob(this.receiptData);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Create download link
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `receipt-${new Date().getTime()}.pdf`;

    // Trigger download
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
}
