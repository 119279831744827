<div class="demo-container">
  <h1 class="demo-title">Demo Server</h1>

  <div class="demo-card">
    <div class="form-group">
      <label for="productId">Product ID</label>
      <input
        id="productId"
        type="text"
        [(ngModel)]="productId"
        placeholder="Enter Product ID"
      />
    </div>

    <button
      class="btn btn-primary"
      (click)="createTransaction()"
      [disabled]="isLoading"
      [class.loading]="isLoading"
    >
      {{ isLoading ? "Processing..." : "Create Transaction" }}
    </button>

    @if (error) {
    <div class="error-message">
      {{ error }}
    </div>
    } @if (success) {
    <div class="success-container">
      <div class="success-card">
        <div class="success-title">Transaction created successfully!</div>
        @if (transactionDetails) {
        <div class="transaction-details">
          <p>
            <span>Transaction ID:</span>
            {{ transactionDetails.TransactionId }}
          </p>
          <p>
            <span>OTP:</span>
            {{ transactionDetails.TransactionOTP }}
          </p>
        </div>
        }
      </div>
      @if (receiptData) {
      <button class="btn btn-success" (click)="downloadReceipt()">
        Download Receipt
      </button>
      }
    </div>
    }
  </div>
</div>
