import { Injectable, inject } from '@angular/core';
import { first, map, BehaviorSubject, Observable, of } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { Branch } from '../../branch/util/branch.model';
import { CustomHttpClientService } from '../../core/services/custom-http-client.service';
import { Banner } from '../util/banner.model';
import { Brand } from '../util/brand.model';
import { EventCategory } from '../util/event-category.model';
import { productApis } from '../util/product.apis';
import { Product } from '../util/product.model';
import { Trending } from '../util/trending.model';
import { CacheService } from '../../core/services/cache.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private customHttp = inject(CustomHttpClientService);
  private cacheService = inject(CacheService);

  private readonly CACHE_KEYS = {
    PRODUCTS: 'cached_products',
    BANNERS: 'cached_banners',
    EVENTS: 'cached_events',
    BRANDS: 'cached_brands',
    TRENDINGS: 'cached_trendings',
    BRANCHES: 'cached_branches',
  };

  private productsSubject = new BehaviorSubject<Product[]>([]);

  getProducts(id: number, recommended: boolean = false) {
    return this.customHttp
      .get<Branch>(productApis.getProducts(id, recommended))
      .pipe(
        first(),
        map((res) => {
          const t2 = new Branch();
          Object.assign(t2, {
            ...res,
            location: res.location
              ? { lat: Number(res.location.lat), lng: Number(res.location.lng) }
              : null,
          });
          return t2;
        })
      );
  }

  getAllProducts(recommended: boolean = false) {
    return this.cacheService.getCachedData(this.CACHE_KEYS.PRODUCTS, () =>
      this.customHttp
        .get<Product[]>(productApis.getAllProducts(recommended))
        .pipe(
          first(),
          map((res) => {
            for (let i = 0; i < res?.length; i++) {
              const t2 = new Product();
              Object.assign(t2, res[i]);
              res[i] = t2;
            }
            return res;
          })
        )
    );
  }

  getBanners() {
    return this.cacheService.getCachedData(this.CACHE_KEYS.BANNERS, () =>
      this.customHttp.get<Banner[]>(productApis.getBanners).pipe(
        first(),
        map((res) => {
          for (let i = 0; i < res?.length; i++) {
            const t2 = new Banner();
            Object.assign(t2, res[i]);
            res[i] = t2;
          }
          return res;
        })
      )
    );
  }

  getEvents() {
    return this.cacheService.getCachedData(this.CACHE_KEYS.EVENTS, () =>
      this.customHttp.get<EventCategory[]>(productApis.getEvents).pipe(
        first(),
        map((res) => {
          for (let i = 0; i < res?.length; i++) {
            const t2 = new EventCategory();
            const category = res[i];
            if (category.reservation_events) {
              category.reservation_events = category.reservation_events.map(
                (event) => {
                  if (typeof event.gallery === 'string') {
                    try {
                      event.gallery = JSON.parse(event.gallery);
                      // Sort gallery to put videos first
                      event.gallery.sort((a, b) => {
                        const isVideoA = /\.(mp4|mov|avi|wmv|flv|mkv)$/i.test(
                          a
                        );
                        const isVideoB = /\.(mp4|mov|avi|wmv|flv|mkv)$/i.test(
                          b
                        );
                        return isVideoB ? 1 : isVideoA ? -1 : 0;
                      });
                    } catch {
                      event.gallery = [];
                    }
                  }
                  return event;
                }
              );
            }
            Object.assign(t2, category);
            res[i] = t2;
          }
          return res;
        })
      )
    );
  }

  getEventItem(eventId: number) {
    return this.getEvents().data$.pipe(
      map((categories) => {
        for (let category of categories) {
          const event = category.reservation_events.find(
            (event) => event.id === eventId
          );

          if (event) {
            event.gallery =
              event.gallery == null ? [event.image] : event.gallery;
            return event;
          }
        }
        return null;
      })
    );
  }

  getBrands() {
    return this.cacheService.getCachedData(this.CACHE_KEYS.BRANDS, () =>
      this.customHttp.get<Brand[]>(productApis.getBrands).pipe(
        first(),
        map((res) => {
          for (let i = 0; i < res?.length; i++) {
            const t2 = new Brand();
            Object.assign(t2, res[i]);
            res[i] = t2;
          }
          return res.sort(
            (a, b) =>
              (a.order ?? Number.MAX_VALUE) - (b.order ?? Number.MAX_VALUE)
          );
        })
      )
    );
  }

  getBranches() {
    return this.cacheService.getCachedData(this.CACHE_KEYS.BRANCHES, () =>
      this.customHttp.get<Branch[]>(productApis.getBranches).pipe(
        first(),
        map((res) => {
          for (let i = 0; i < res?.length; i++) {
            const t2 = new Branch();
            Object.assign(t2, res[i]);
            res[i] = t2;
          }
          return res;
        })
      )
    );
  }

  getTrendings() {
    return this.cacheService.getCachedData(this.CACHE_KEYS.TRENDINGS, () =>
      this.customHttp.get<Trending[]>(productApis.getTrendings).pipe(
        first(),
        map((res) => {
          for (let i = 0; i < res?.length; i++) {
            const t2 = new Trending();
            Object.assign(t2, res[i]);
            res[i] = t2;
          }
          return res;
        })
      )
    );
  }

  // Helper method to check if we have cached data
  hasCachedProducts(): boolean {
    return !!localStorage.getItem(this.CACHE_KEYS.PRODUCTS);
  }
}
