import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { RouterModule } from '@angular/router';
import { Product } from '../../../../../products/util/product.model';
import { AllowNumbersAndLettersDirective } from '../../../../../directives/allow-numbers-and-letters.directive';
import { ApiTranslationPipe } from '../../../../../core/pipes/api-translation.pipe';
import { NgxIkImageCdnComponent } from '../../../../../shared/ngx-ik-image-cdn/ngx-ik-image-cdn.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cart-item',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    FormsModule,
    ButtonModule,
    RippleModule,
    RouterModule,
    AllowNumbersAndLettersDirective,
    ApiTranslationPipe,
    NgxIkImageCdnComponent,
    TranslateModule,
  ],
  template: `
    <div class="cart-item" [ngClass]="{ error: isError }">
      <div class="item-header">
        <div class="item-icon">
          <app-ngx-ik-image-cdn
            [path]="product.branch.icon"
          ></app-ngx-ik-image-cdn>
        </div>
        <div class="item-details">
          <div class="item-title">{{ product.localProduct.name }}</div>
          <div class="item-subtitle">{{ product.branch.name }}</div>
        </div>
        <div class="item-actions">
          <div class="action delete" (click)="onRemove()">
            <i class="pi pi-trash"></i>
          </div>
        </div>
      </div>

      <div class="item-body">
        <div class="quantity-controls">
          <div class="action" (click)="onDecrement()">
            <div class="outline-circle">
              <i class="pi pi-minus"></i>
            </div>
          </div>
          <p class="quantity">{{ product.quantity }}</p>
          <div class="action" (click)="onIncrement()">
            <div class="outline-circle">
              <i class="pi pi-plus"></i>
            </div>
          </div>
        </div>

        <div class="item-price">
          <p>{{ product.Price }} SAR</p>
        </div>
      </div>

      <div class="card-input-section">
        <div class="input-container">
          <label [for]="'cardNumber-' + index">
            {{ 'booking.cart.enter_card_number' | apiTranslation }}
          </label>
          <div class="card-input-wrapper">
            <input
              [id]="'cardNumber-' + index"
              name="cardNumber"
              type="text"
              appAllowNumbersAndLetters
              [(ngModel)]="cardNumber"
              (ngModelChange)="onCardNumberChange($event)"
              [ngClass]="{
                valid: cardNumberStatus === true,
                invalid: cardNumberStatus === false
              }"
            />
          </div>

          <p class="card-validation-message" *ngIf="cardNumberStatus === true">
            {{ 'booking.cart.product.name' | apiTranslation }}
            {{ cardNumberName }}
          </p>
          <p
            class="card-validation-message error"
            *ngIf="cardNumberStatus === false"
          >
            {{ 'booking.cart.card_name_error' | apiTranslation }}
          </p>
        </div>

        <div class="saved-cards" *ngIf="ownCards && ownCards.length > 0">
          <label>{{ 'booking.cart.choose_from_yours' | translate }}</label>
          <div class="cards-container">
            <ng-container *ngFor="let card of ownCards">
              <div class="card-item" (click)="selectCard(card)">
                <p>{{ card }}</p>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="item-details-link">
        <div class="view-details-button">
          <button
            pButton
            pRipple
            [label]="'branches.products.product.view_details' | apiTranslation"
            icon="pi pi-arrow-up-left"
            iconPos="right"
            [routerLink]="'/view/' + product.localProduct.product_id"
          ></button>
        </div>
        <div class="card-info">
          <app-ngx-ik-image-cdn
            path="images/malahi-card.png"
          ></app-ngx-ik-image-cdn>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./cart-item.component.scss'],
})
export class CartItemComponent {
  @Input() product!: Product & { quantity: number };
  @Input() index!: number;
  @Input() isError: boolean = false;
  @Input() ownCards: string[] = [];

  @Input() set initialCardNumber(value: string | null) {
    this._cardNumber = value || '';
  }

  @Input() set cardValidityStatus(value: boolean | null) {
    this._cardNumberStatus = value;
  }

  @Input() set cardOwnerName(value: string | null) {
    this._cardNumberName = value;
  }

  @Output() remove = new EventEmitter<void>();
  @Output() increment = new EventEmitter<void>();
  @Output() decrement = new EventEmitter<void>();
  @Output() cardChange = new EventEmitter<string>();
  @Output() selectSavedCard = new EventEmitter<string>();

  private _cardNumber: string = '';
  private _cardNumberStatus: boolean | null = null;
  private _cardNumberName: string | null = null;

  get cardNumber(): string {
    return this._cardNumber;
  }

  set cardNumber(value: string) {
    this._cardNumber = value;
  }

  get cardNumberStatus(): boolean | null {
    return this._cardNumberStatus;
  }

  get cardNumberName(): string | null {
    return this._cardNumberName;
  }

  onRemove(): void {
    this.remove.emit();
  }

  onIncrement(): void {
    this.increment.emit();
  }

  onDecrement(): void {
    this.decrement.emit();
  }

  onCardNumberChange(value: string): void {
    this.cardChange.emit(value);
  }

  selectCard(card: string): void {
    this._cardNumber = card;
    this.selectSavedCard.emit(card);
  }
}
