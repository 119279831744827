import { Injectable, inject } from '@angular/core';
import { first, map, tap } from 'rxjs';
import { Branch } from '../../branch/util/branch.model';
import { CustomHttpClientService } from '../../core/services/custom-http-client.service';
import { User } from '../util/user.model';
import { Card } from '../util/card.model';
import { authApis } from '../util/auth.apis';
import { CacheService } from '../../core/services/cache.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private customHttp = inject(CustomHttpClientService);
  private cacheService = inject(CacheService);

  private readonly CACHE_KEYS = {
    CARDS: 'cached_cards',
  };

  getUserInfo() {
    return this.customHttp.get<User>(authApis.userInfo).pipe(
      first(),
      map((res: any) => {
        const user = new User();
        Object.assign(user, res['user']);
        return user;
      })
    );
  }

  updateMyInfo(user: User) {
    return this.customHttp.put<User>(authApis.userInfo, user).pipe(
      first(),
      map((res) => {
        const user = new User();
        Object.assign(user, res);
        return user;
      })
    );
  }

  getAllCards() {
    return this.cacheService.getCachedData(this.CACHE_KEYS.CARDS, () =>
      this.customHttp.get<Card[]>(authApis.getUserCards).pipe(
        first(),
        map((res) => {
          const cards = res.map((card) => {
            const c = new Card();
            Object.assign(c, card);
            return c;
          });
          return cards;
        })
      )
    );
  }

  getAllCardsWithoutDetails() {
    return this.customHttp
      .get<Card[]>(authApis.getUserCardsWithoutDetails)
      .pipe(
        first(),
        map((res) => {
          const cards = res.map((card) => {
            const c = new Card();
            Object.assign(c, card);
            return c;
          });
          return cards;
        })
      );
  }

  addCard(card_number: string) {
    return this.customHttp.post<Card>(authApis.addCard, { card_number }).pipe(
      first(),
      tap(() => {
        // Clear the cards cache when adding a new card
        this.cacheService.clearCache(this.CACHE_KEYS.CARDS);
      })
    );
  }

  deleteCard(id: number) {
    return this.customHttp.delete(`${authApis.deleteCard}/${id}`).pipe(
      first(),
      tap(() => {
        // Clear the cards cache when deleting a card
        this.cacheService.clearCache(this.CACHE_KEYS.CARDS);
      })
    );
  }
}
