import * as uuid from 'uuid';
import { AsyncPipe, DecimalPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { FooterComponent } from '../../../core/widgets/footer/footer.component';
import { HeaderComponent } from '../../../core/widgets/header/header.component';
import { Product } from '../../../products/util/product.model';
import { CartService } from '../../data-access/cart.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CheckoutService } from '../../data-access/checkout.service';
import {
  Observable,
  Subject,
  Subscription,
  catchError,
  filter,
  finalize,
  first,
  of,
  tap,
  takeUntil,
} from 'rxjs';
import { AllowNumbersAndLettersDirective } from '../../../directives/allow-numbers-and-letters.directive';
import { LanguageService } from '../../../core/services/language-service/language.service';
import { ApiTranslationPipe } from '../../../core/pipes/api-translation.pipe';
import { NgxIkImageCdnComponent } from '../../../shared/ngx-ik-image-cdn/ngx-ik-image-cdn.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../../user/data-access/profile.service';
import { environment } from '../../../../environments/environment';
import {
  NgxPhoneNumberInputModule,
  PhoneInputComponent,
} from 'ngx-lib-phone-input';
import { User } from '../../../user/util/user.model';
import { Transaction } from '../../util/transaction.model';
import { CountryListItemType, countries } from 'country-list-json';
import { FacebookPixelEventTrackerService } from '../../../core/services/facebook-pixel-event-tracker.service';
import { SnapEventTrackerService } from '../../../core/services/snap-event-tracker.service';
import { GoogleEventTrackerService } from '../../../core/services/google-event-tracker.service';
import { TiktokEventTrackerService } from '../../../core/services/tiktok-event-tracker.service';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { SkeletonModule } from 'primeng/skeleton';
import { DividerModule } from 'primeng/divider';
import { CartItemComponent } from './components/cart-item/cart-item.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';

declare var TamaraWidgetV2: any;
declare var TabbyPromo: any;

declare global {
  interface Window {
    tamaraWidgetConfig: any;
  }
}

declare global {
  interface Window {
    TamaraWidgetV2: any;
  }
}

@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [
    NgFor,
    DecimalPipe,
    RouterModule,
    FooterComponent,
    NgClass,
    ButtonModule,
    RippleModule,
    ToastModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    AllowNumbersAndLettersDirective,
    ApiTranslationPipe,
    NgxIkImageCdnComponent,
    TranslateModule,
    AsyncPipe,
    CreditCardDirectivesModule,
    SkeletonModule,
    NgxPhoneNumberInputModule,
    DividerModule,
    CartItemComponent,
  ],
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  providers: [MessageService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CartComponent implements OnInit, OnDestroy, AfterViewInit {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private cartService = inject(CartService);
  private profileService = inject(ProfileService);
  private languageService = inject(LanguageService);
  private messageService = inject(MessageService);
  private changeDetector = inject(ChangeDetectorRef);
  private checkoutService = inject(CheckoutService);
  private translateService = inject(TranslateService);
  private formBuilder = inject(FormBuilder);

  cart: (Product & { quantity: number })[] = [];
  errorIndex: number | null = null;
  transactions: Transaction[] = [];
  selectedPaymentMethod: string = 'visaCard';
  user!: User;
  userForm!: FormGroup;
  allCountries: CountryListItemType[] = [];
  tiktokContent: any[] = [];

  cardNumberInput: Record<string, string | null> = {};
  cardNumberStatus: Record<string, boolean | null> = {};
  cardNumberName: Record<string, string | null> = {};
  ownCards: Record<string, string[]> = {};
  cardNumbers: Record<string, string> = {};
  checkCardLoading: boolean = false;

  isLoading: boolean = false;
  isTabbyLoading: boolean = false;
  isTabbyAllowed: boolean = true;
  isTamaraLoading: boolean = false;
  isTamaraAllowed: boolean = true;
  isAllLoading: boolean = false;
  isCouponLoading: boolean = false;

  validCoupon!: boolean;
  userFormSubmitted: boolean = false;
  creditFormSubmitted: boolean = false;
  isValidEmail: boolean = true;

  redirectingUrl!: string;
  counter: number = 5;
  intervalId: any;
  success: boolean = false;

  errors: string[] = [];
  applePayErrors: string[] = [];
  creditErrors: string[] = [];

  transaction$?: Observable<Transaction[] | null>;
  appleTransaction$?: Observable<any>;
  payment$?: Observable<any>;
  private cardNumberChange$ = new Subject<{
    siteId: string;
    cardNumber: string;
  }>();
  private destroy$ = new Subject<void>();

  @ViewChild(PhoneInputComponent) phoneInputComponent!: PhoneInputComponent;

  coupon!: string;

  ngOnInit(): void {
    this.initializeCart();
    this.setupRouteHandling();
    this.setupCardValidation();
    this.initializeUserForm();
    this.startTransactionProcess();
  }

  ngAfterViewInit(): void {
    this.setupPhoneInput();
    this.getUserInfo();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.removeTabbyScript();
  }

  private initializeCart(): void {
    this.cart = this.cartService.getItems();

    if (!this.cart.length) {
      this.router.navigate(['/']);
      return;
    }

    this.cart.forEach((prod) => {
      const siteId = prod.branch.siteId.toString();
      this.cardNumberInput[siteId] = null;
      this.cardNumberStatus[siteId] = null;
      this.cardNumberName[siteId] = null;
    });

    this.getAllCards();
  }

  private setupRouteHandling(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        window.scrollTo(0, 0);
      });

    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.handleQueryParams(params);
      });
  }

  private handleQueryParams(params: any): void {
    if (params['error']) {
      this.errorIndex = params['error'];
      setTimeout(() => {
        this.messageService.add({
          severity: 'error',
          summary: this.languageService.translate('general.toast.error'),
          detail: this.languageService.translate('booking.checkout.error'),
        });
      }, 100);
    }

    if (params['payment_id']) {
      this.handleTabbyPaymentId(params['payment_id']);
    }
  }

  private handleTabbyPaymentId(paymentId: string): void {
    this.isTabbyLoading = true;
    this.changeDetector.markForCheck();

    this.checkoutService
      .getTabbyTransaction(paymentId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isTabbyLoading = false;
          this.changeDetector.markForCheck();
        })
      )
      .subscribe({
        next: (res) => {
          if (res['status'] === 'REJECTED') {
            this.errors = [this.translateService.instant('errors.tabby_error')];
            this.isTabbyAllowed = false;
            this.changeDetector.markForCheck();
          }
        },
        error: () => {
          this.isTabbyAllowed = false;
          this.changeDetector.markForCheck();
        },
      });
  }

  private setupCardValidation(): void {
    this.cardNumberChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ siteId, cardNumber }) => {
        this.checkCardValidity(siteId, cardNumber);
      });
  }

  private initializeUserForm(): void {
    this.userForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.pattern('^[- +()0-9]+$'),
          Validators.minLength(10),
        ],
      ],
      termsCheck: [false, Validators.requiredTrue],
    });

    this.selectedPaymentMethod = 'visaCard';
  }

  private startTransactionProcess(): void {
    const items = this.prepareTransactionItems();

    this.isLoading = true;

    this.transaction$ = this.checkoutService.beginTransaction(items).pipe(
      tap((value) => {
        this.transactions = value;
        this.trackBeginCheckout();
        this.prepareTiktokContent();
        this.loadTabby();
        this.isLoading = false;
      }),
      catchError((error) => {
        this.isLoading = false;
        return of(null);
      })
    );
  }

  private prepareTransactionItems(): any[] {
    return this.cart.map((product) => ({
      ProductId: product.ProductId,
      Quantity: product.quantity,
      SiteId: product.SiteId,
      CardNumber: this.cardNumbers[product.SiteId] ?? null,
    }));
  }

  private trackBeginCheckout(): void {
    GoogleEventTrackerService.trackEvent('begin_checkout', {
      items: this.cart,
    });
    FacebookPixelEventTrackerService.trackEvent('begin_checkout', {
      items: this.cart,
    });

    SnapEventTrackerService.trackEvent('START_CHECKOUT', {
      price: this.totalTransactionAmount('TransactionNetAmount'),
      currency: 'SAR',
      item_ids: this.cart.map((obj) => `${obj.ProductId}`),
      item_category: 'Malahi',
      number_items: this.cart.reduce((sum, obj) => sum + obj.quantity, 0),
      payment_info_available: 0,
    });
  }

  private prepareTiktokContent(): void {
    this.tiktokContent = [];

    this.transactions.forEach((transaction) => {
      transaction.TransactionLinesDTOList.forEach((line) => {
        const existingItem = this.tiktokContent.find(
          (item) => item.content_id === `${line.ProductId}`
        );

        if (existingItem) {
          existingItem.quantity += 1;
        } else {
          this.tiktokContent.push({
            content_id: `${line.ProductId}`,
            content_type: 'product',
            content_category: `${transaction.SiteId}`,
            content_name: `${line.ProductName}`,
            quantity: 1,
            price: `${line.Amount}`,
          });
        }
      });
    });

    TiktokEventTrackerService.trackEvent('InitiateCheckout', {
      contents: this.tiktokContent,
      value: `${this.totalTransactionAmount('TransactionNetAmount')}`,
      currency: 'SAR',
    });
  }

  private setupPhoneInput(): void {
    if (!this.phoneInputComponent) return;

    this.allCountries = countries
      .filter((country) => country.code !== 'IL')
      .map((country) => {
        if (country.code === 'PS') {
          return { ...country, name: 'Palestine' };
        }
        return country;
      });

    this.phoneInputComponent.countriesWithCodes = this.allCountries;
    this.phoneInputComponent.countrySelect(
      this.allCountries.find((country) => country.code === 'SA')!
    );

    this.phoneInputComponent.registerOnChange((event: any) => {
      this.registerOnChange(event);
    });
  }

  getAllCards(): void {
    if (!localStorage.getItem(environment.jwtKey)) return;

    const { data$, loading } = this.profileService.getAllCards();

    data$.pipe(takeUntil(this.destroy$)).subscribe((cards) => {
      cards.forEach((card) => {
        card.semnox_details['SiteId'].forEach((siteId: string) => {
          if (this.ownCards[siteId]) {
            this.ownCards[siteId].push(card.card_number);
          } else {
            this.ownCards[siteId] = [card.card_number];
          }
        });
      });
    });
  }

  removeItem(productId: number, index: number): void {
    this.cartService.removeItem(productId);
    this.cart = this.cartService.getItems();

    if (this.cart.length === 0) {
      this.router.navigate(['/']);
      return;
    }

    if (index === this.errorIndex) {
      this.errorIndex = null;
    }

    this.refreshTransactionProcess();
  }

  incrementItem(productId: number): void {
    this.cartService.increaseQuantity(productId);
    this.cart = this.cartService.getItems();

    this.refreshTransactionProcess();
  }

  decrementItem(productId: number): void {
    this.cartService.decreaseQuantity(productId);
    this.cart = this.cartService.getItems();

    if (this.cart.length === 0) {
      this.router.navigate(['/']);
      return;
    }

    this.refreshTransactionProcess();
  }

  private refreshTransactionProcess(): void {
    this.transactions = [];

    if (this.cart.length > 0) {
      this.startTransactionProcess();
    }
  }

  preCheckout(): boolean {
    let status = true;

    Object.keys(this.cardNumberInput).forEach((key) => {
      const value = this.cardNumberInput[key];
      if ((value && value.length < 8) || this.cardNumberStatus[key] === false) {
        this.cardNumberStatus[key] = false;
        status = false;
      }
    });

    if (!status) {
      this.messageService.add({
        severity: 'error',
        summary: this.languageService.translate('general.toast.error'),
        detail: this.languageService.translate(
          'booking.cart.card_number_error'
        ),
      });
      return status;
    }

    this.cardNumbers = Object.keys(this.cardNumberName)
      .filter((key) => this.cardNumberName[key] !== null)
      .reduce((obj, key) => {
        obj[key] = this.cardNumberInput[key]!;
        return obj;
      }, {} as Record<string, string>);

    return status;
  }

  onCardNumberChange(siteId: string, cardNumber: string): void {
    if (cardNumber && cardNumber.length === 8 && siteId) {
      this.checkCardLoading = true;
      cardNumber = cardNumber.toUpperCase();
      this.cardNumberChange$.next({ siteId, cardNumber });
    } else {
      this.cardNumberStatus[siteId] = null;
      this.cardNumberName[siteId] = null;
      this.changeDetector.markForCheck();
    }
  }

  checkCardValidity(branch: string, cardNumber: string): void {
    this.checkoutService
      .checkCardValidity(branch, cardNumber)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.checkCardLoading = false;
        })
      )
      .subscribe({
        next: (res) => {
          this.cardNumberStatus[branch] = res === null ? false : true;
          if (this.cardNumberStatus[branch]) {
            this.cardNumberName[branch] = res;
          }
          this.changeDetector.markForCheck();
        },
        error: () => {
          this.cardNumberStatus[branch] = false;
          this.changeDetector.markForCheck();
        },
      });
  }

  getUserInfo(): void {
    if (!localStorage.getItem(environment.jwtKey)) {
      this.loadStoredUserInfo();
      return;
    }

    this.profileService
      .getUserInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.user = user;
        this.populateUserForm(user);
      });
  }

  private loadStoredUserInfo(): void {
    if (!localStorage.getItem('userInfo')) return;

    try {
      const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '');

      this.userForm.patchValue({
        email: userInfo.email,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
      });

      setTimeout(() => {
        this.userForm.get('phoneNumber')?.setValue(userInfo.phoneNumber);
      }, 100);
    } catch (error) {
      console.error('Error loading stored user info', error);
    }
  }

  private populateUserForm(user: User): void {
    const nameParts = user.name.split(' ');

    this.userForm.patchValue({
      email: user.email,
      firstName: nameParts[0],
      lastName: nameParts.length > 1 ? nameParts[1] : '',
      phoneNumber: user.phone_number,
    });
  }

  startCountdown(): void {
    this.intervalId = setInterval(() => {
      this.counter--;
      this.changeDetector.markForCheck();

      if (this.counter <= 0) {
        clearInterval(this.intervalId);
      }
    }, 1000);
  }

  loadTabby(): void {
    if (typeof (window as any).TabbyPromo === 'undefined') {
      const script = document.createElement('script');
      script.src = 'https://checkout.tabby.ai/tabby-promo.js';
      script.id = 'tabby-script';
      script.onload = () => this.initTabby();
      document.body.appendChild(script);
    } else {
      this.initTabby();
    }
  }

  private removeTabbyScript(): void {
    const existingScript = document.querySelector('script#tabby-script');
    if (existingScript) {
      existingScript.remove();
    }
  }

  initTabby(): void {
    try {
      new (window as any).TabbyPromo({
        selector: '#tabbyCard',
        publicKey: environment.tabbyPublicKey,
        installmentsCount: 4,
        merchantCode: environment.merchantCode,
        currency: 'SAR',
        lang: this.languageService.getLanguage(),
        price: this.totalTransactionAmount('TransactionNetAmount'),
        size: 'wide',
        theme: 'green',
        header: true,
      });

      (window as any).tamaraWidgetConfig = {
        lang: this.languageService.getLanguage(),
        country: 'SA',
        publicKey: environment.tamaraPublicKey,
        css: '.tamara-summary-widget--inline-outlined:hover {background-color: #272727 !important;}',
      };

      if ((window as any).TamaraWidgetV2) {
        (window as any).TamaraWidgetV2.refresh();
      }
    } catch (error) {
      console.error('Error initializing payment widgets', error);
    }
  }

  selectPaymentMethod(method: string): void {
    this.selectedPaymentMethod = method;
  }

  totalTransactionAmount(field: string): number {
    return this.transactions.reduce(
      (sum, current) => sum + current[field as keyof Transaction],
      0
    );
  }

  flattenTransactionLines(): any[] {
    const list = this.transactions.flatMap(
      (transaction) => transaction.TransactionLinesDTOList
    );

    const result: any[] = [];
    const itemMap: Record<string, number> = {};

    list.forEach((item) => {
      const key = `${item.ProductId}`;

      if (itemMap.hasOwnProperty(key)) {
        result[itemMap[key]].quantity += 1;
        result[itemMap[key]].Price += item.Price;
      } else {
        itemMap[key] = result.length;
        result.push({ ...item, quantity: 1 });
      }
    });

    return result;
  }

  totalTax(): number {
    return this.cartService.totalTax();
  }

  totalPrice(): number {
    return this.cartService.totalPrice();
  }

  applyCoupon(): void {
    if (!this.coupon) return;

    this.isCouponLoading = true;
    this.changeDetector.markForCheck();

    this.transaction$ = this.checkoutService
      .applyCoupon(this.transactions, this.coupon)
      .pipe(
        tap((value) => {
          const userInfo = this.getUserInfoForTracking();
          this.transactions = value;
          this.validCoupon = true;

          this.trackCouponApplication(userInfo);
        }),
        catchError(() => {
          this.validCoupon = false;
          return of(null);
        }),
        finalize(() => {
          this.isCouponLoading = false;
          this.changeDetector.markForCheck();
        })
      );
  }

  private getUserInfoForTracking(): any {
    try {
      return JSON.parse(
        localStorage.getItem('userInfo') ??
          JSON.stringify({
            email: 'null',
            firstName: 'null',
            lastName: 'null',
            phoneNumber: 'null',
          })
      );
    } catch (error) {
      console.error('Error getting user info for tracking', error);
      return {
        email: 'null',
        firstName: 'null',
        lastName: 'null',
        phoneNumber: 'null',
      };
    }
  }

  private trackCouponApplication(userInfo: any): void {
    GoogleEventTrackerService.trackEvent('apply_coupon', {
      coupon: this.coupon,
    });

    FacebookPixelEventTrackerService.trackEvent('apply_coupon', {
      coupon: this.coupon,
    });

    SnapEventTrackerService.trackEvent('CUSTOM_EVENT_1', {
      uuid_c1: uuid.v4(),
      event_tag: 'apply_coupon',
      user_email: userInfo.email,
      user_phone_number: userInfo.phoneNumber,
      firstname: userInfo.firstName,
      lastname: userInfo.lastName,
    });
  }

  async processPayment(paymentMethod: string): Promise<void> {
    if (!this.preCheckout()) return;

    this.userFormSubmitted = true;
    this.creditFormSubmitted = true;

    if (!this.isValidUserForm()) {
      return;
    }

    this.isAllLoading = true;
    this.changeDetector.markForCheck();

    const isValidEmail = await this.validateEmail(
      this.userForm.get('email')?.value
    );
    if (!isValidEmail && environment.production) {
      this.isAllLoading = false;
      this.changeDetector.markForCheck();
      return;
    }

    this.trackOrderPlacement(paymentMethod);

    switch (paymentMethod) {
      case 'tabby':
        this.processTabbyPayment();
        break;
      case 'tamara':
        this.processTamaraPayment();
        break;
      default:
        this.processCardPayment();
    }
  }

  async tabbyCheckout(): Promise<void> {
    await this.processPayment('tabby');
  }

  async tamaraCheckout(): Promise<void> {
    await this.processPayment('tamara');
  }

  async checkout(): Promise<void> {
    await this.processPayment('card');
  }

  private trackOrderPlacement(paymentMethod: string): void {
    const email = this.userForm.get('email')?.value;
    const phoneNumber = this.userForm.get('phoneNumber')?.value;

    TiktokEventTrackerService.identify(email, phoneNumber);
    TiktokEventTrackerService.trackEvent('PlaceAnOrder', {
      contents: this.tiktokContent,
      value: `${this.totalTransactionAmount('TransactionNetAmount')}`,
      currency: 'SAR',
      description: paymentMethod,
    });
  }

  private processTabbyPayment(): void {
    this.payment$ = this.checkoutService
      .checkoutTabbyTransaction(this.transactions, { ...this.userForm.value })
      .pipe(
        tap((value) => {
          if (value.payment.warnings && value.payment.warnings.length > 0) {
            this.handlePaymentError('errors.tabby_error');
          } else {
            this.handlePaymentSuccess(value, 'tabby');
          }
        }),
        catchError((error) => {
          this.handlePaymentError(error?.error?.message || 'Unexpected error');
          return of(null);
        })
      );

    this.payment$.subscribe();
  }

  private processTamaraPayment(): void {
    const cleanedUserFormValue = {
      ...this.userForm.value,
      phoneNumber: this.userForm.value.phoneNumber.replace(/[-()\s]/g, ''),
    };

    this.payment$ = this.checkoutService
      .checkoutTamaraTransaction(this.transactions, cleanedUserFormValue)
      .pipe(
        tap((value) => {
          if (value.payment.warnings && value.payment.warnings.length > 0) {
            this.handlePaymentError('errors.tabby_error');
          } else {
            this.handlePaymentSuccess(value, 'tamara');
          }
        }),
        catchError((error) => {
          this.handlePaymentError(error?.error?.message || 'Unexpected error');
          return of(null);
        })
      );

    this.payment$.subscribe();
  }

  private processCardPayment(): void {
    this.payment$ = this.checkoutService
      .checkoutTransaction(this.transactions, { ...this.userForm.value })
      .pipe(
        tap((value) => {
          this.handlePaymentSuccess(value, 'card');
        }),
        catchError((error) => {
          this.handlePaymentError(
            error.error.message ??
              this.translateService.instant(
                'errors.applepay.UNSPECIFIED_FAILURE'
              ),
            'credit'
          );
          return of(null);
        })
      );

    this.payment$.subscribe();
  }

  private handlePaymentSuccess(value: any, type: string): void {
    this.storeUserInfo();

    let url = '';
    if (type === 'tabby') {
      try {
        url =
          value.payment.configuration.available_products.installments[0]
            .web_url;
      } catch (err) {
        this.handlePaymentError('Unexpected error!');
        return;
      }
    } else if (type === 'tamara') {
      try {
        url = value.payment.checkout_url;
      } catch (err) {
        this.handlePaymentError('Unexpected error!');
        return;
      }
    } else {
      url = value['transaction_url'];
    }

    this.redirectingUrl = url;
    this.startCountdown();
    this.changeDetector.markForCheck();
    window.location.href = url;
  }

  private handlePaymentError(
    errorMessage: string,
    errorType: string = 'general'
  ): void {
    this.isAllLoading = false;

    if (errorType === 'credit') {
      this.creditErrors = [errorMessage];
    } else {
      this.errors = [this.translateService.instant(errorMessage)];
    }

    this.changeDetector.markForCheck();
  }

  isValidUserForm(): boolean {
    const emailValid = this.userForm.get('email')?.valid ?? false;
    const firstNameValid = this.userForm.get('firstName')?.valid ?? false;
    const lastNameValid = this.userForm.get('lastName')?.valid ?? false;
    const termsCheckValid = this.userForm.get('termsCheck')?.valid ?? false;

    return (
      emailValid &&
      firstNameValid &&
      lastNameValid &&
      this.isValidInput('phoneNumber') &&
      termsCheckValid
    );
  }

  isValidInput(name: string, isUserForm = false): boolean {
    if (!this.userForm) return false;

    const input = this.userForm.get(name);
    if (!input) return false;

    if (name === 'phoneNumber') {
      if (!this.phoneInputComponent) {
        return true;
      }

      const value = input.value.replace(' ', '');
      if (value !== input.value) {
        input.setValue(value);
      }

      const dialCode = this.phoneInputComponent.selectedCountry.dial_code;
      return (
        !this.userFormSubmitted ||
        (value.length - dialCode.length >= 9 &&
          value.length - dialCode.length <= 10 &&
          input.valid)
      );
    }

    return (
      (isUserForm ? this.userFormSubmitted : this.creditFormSubmitted) &&
      (input.invalid || (name === 'email' && !this.isValidEmail))
    );
  }

  async validateEmail(email: string): Promise<boolean> {
    if (!email) return false;

    return new Promise((resolve) => {
      this.checkoutService
        .validateEmail(email)
        .pipe(
          takeUntil(this.destroy$),
          catchError(() => {
            this.isValidEmail = false;
            resolve(false);
            return of(null);
          })
        )
        .subscribe({
          next: () => {
            this.isValidEmail = true;
            resolve(true);
          },
          error: () => {
            this.isValidEmail = false;
            resolve(false);
          },
        });
    });
  }

  storeUserInfo(): void {
    localStorage.setItem(
      'userInfo',
      JSON.stringify({
        email: this.userForm.get('email')?.value ?? '',
        firstName: this.userForm.get('firstName')?.value ?? '',
        lastName: this.userForm.get('lastName')?.value ?? '',
        phoneNumber: this.userForm.get('phoneNumber')?.value ?? '',
      })
    );
  }

  registerOnChange(number: any): void {
    const phoneNumberControl = this.userForm.get('phoneNumber');
    if (phoneNumberControl && phoneNumberControl.value !== number) {
      phoneNumberControl.setValue(number);
    }
  }
}
