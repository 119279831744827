<div class="content">
  <div class="container">
    <div class="section flex flex-column align-items-center">
      <div class="section-header">
        <div class="section-title">
          <h1>
            {{ "booking.user.log_to_your_account" | translate }}
          </h1>
        </div>
      </div>
      <div class="section-content">
        <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
          <p-card
            subheader="{{ 'booking.user.log_in_desc' | translate }}"
            [style]="{ width: '40rem' }"
          >
            <div class="flex flex-wrap gap-3">
              <!-- Name -->
              <div class="flex flex-column gap-2 col-md-6">
                <label for="name">{{ "auth.name" | translate }}</label>
                <p-inputGroup>
                  <p-inputGroupAddon>
                    <i class="pi pi-user"></i>
                  </p-inputGroupAddon>
                  <input
                    type="text"
                    pInputText
                    placeholder="Name"
                    formControlName="name"
                    [ngClass]="{
                      'ng-invalid ng-dirty': signUpForm.get('name')?.invalid
                    }"
                    required
                  />
                </p-inputGroup>
              </div>

              <!-- Phone number -->
              <div class="flex flex-column gap-2 col-md-6">
                <label for="phone_number">{{ "auth.phone" | translate }}</label>
                <p-inputGroup>
                  <p-inputGroupAddon>
                    <i class="pi pi-phone"></i>
                  </p-inputGroupAddon>
                  <ngx-lib-phone-input
                    dir="ltr"
                    formControlName="phone_number"
                    phoneValidation="true"
                    [ngClass]="{
                      'ng-invalid ng-dirty':
                        signUpForm.get('phone_number')?.invalid ||
                        errors.hasOwnProperty('phone_number')
                    }"
                  ></ngx-lib-phone-input>
                </p-inputGroup>
              </div>

              <!-- Email -->
              <div class="flex flex-column gap-2 col-md-6">
                <label for="email">{{ "auth.email" | translate }}</label>
                <p-inputGroup>
                  <p-inputGroupAddon>
                    <i class="pi pi-at"></i>
                  </p-inputGroupAddon>
                  <input
                    type="email"
                    pInputText
                    placeholder="name@mail.com"
                    formControlName="email"
                    [ngClass]="{
                      'ng-invalid ng-dirty':
                        signUpForm.get('email')?.invalid ||
                        errors.hasOwnProperty('email')
                    }"
                    required
                  />
                </p-inputGroup>
              </div>

              <!-- Password -->
              <div class="flex flex-column gap-2 col-md-6">
                <label for="password">{{ "auth.password" | translate }}</label>
                <p-inputGroup>
                  <p-inputGroupAddon>
                    <i class="pi pi-key"></i>
                  </p-inputGroupAddon>
                  <input
                    type="password"
                    pInputText
                    placeholder="********"
                    formControlName="password"
                    [ngClass]="{
                      'ng-invalid ng-dirty': signUpForm.get('password')?.invalid
                    }"
                    required
                  />
                </p-inputGroup>
              </div>

              <!-- Password Confirmation -->
              <div class="flex flex-column gap-2 col-md-6">
                <label for="password_confirmation">{{
                  "auth.confirm_password" | translate
                }}</label>
                <p-inputGroup>
                  <p-inputGroupAddon>
                    <i class="pi pi-key"></i>
                  </p-inputGroupAddon>
                  <input
                    type="password"
                    pInputText
                    placeholder="********"
                    formControlName="password_confirmation"
                    [ngClass]="{
                      'ng-invalid ng-dirty': signUpForm.get(
                        'password_confirmation'
                      )?.invalid
                    }"
                    required
                  />
                </p-inputGroup>
              </div>

              <!-- Accept terms and conditions -->
              <div class="flex flex-row gap-2 w-full"></div>
              <div class="flex flex-row gap-2">
                <p-checkbox
                  name="acceptTerms"
                  formControlName="acceptTerms"
                  [binary]="true"
                  inputId="binary"
                ></p-checkbox>
                <label for="acceptTerms"
                  >{{
                    "booking.checkout.details.payment_details.terms_check_1"
                      | translate
                  }}
                  <a routerLink="/content/termsAndConditions">
                    {{
                      "booking.checkout.details.payment_details.terms_check_2"
                        | translate
                    }}</a
                  ></label
                >
              </div>
            </div>
            <div
              class="message-container error mt-4"
              *ngIf="isTermsError() || isErrors()"
            >
              <div class="flex flex-column gap-2">
                <div class="flex flex-row" *ngIf="isTermsError()">
                  <i class="pi pi-exclamation-circle"></i>
                  <p>
                    {{ "form_validation.errors.termsCheck_field" | translate }}
                  </p>
                </div>
                <ng-container *ngIf="isErrors()">
                  <ng-container *ngFor="let errorArr of errorKeys">
                    <div
                      class="flex flex-row"
                      *ngFor="let error of errors[errorArr]"
                    >
                      <i class="pi pi-exclamation-circle"></i>
                      <p>
                        {{ error | translate }}
                      </p>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <ng-template pTemplate="footer">
              <div class="flex gap-3 mt-1">
                <p-button
                  label="{{ 'booking.user.sign_up' | translate }}"
                  class="w-full"
                  styleClass="w-full"
                  type="submit"
                  [loading]="loading"
                />
              </div>
              <!-- Dont have an account? Sign Up -->
              <p class="mt-4 text-center">
                {{ "booking.cart.have_account" | translate }}
                <a routerLink="/login">{{
                  "booking.cart.sign_in" | translate
                }}</a>
              </p>
            </ng-template>
          </p-card>
        </form>
      </div>
    </div>
    <div class="section">
      <app-footer></app-footer>
    </div>
  </div>
</div>

<p-toast [position]="'top-center'"></p-toast>
