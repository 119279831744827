import { AsyncPipe, NgFor, NgIf, NgStyle } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  inject,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { GalleriaModule } from 'primeng/galleria';
// import { PhotoService } from '@service/photoservice';
import { SkeletonModule } from 'primeng/skeleton';
import { ToastModule } from 'primeng/toast';
import { Observable, map } from 'rxjs';
import { CartService } from '../../../booking/data-access/cart.service';
import { FooterComponent } from '../../../core/widgets/footer/footer.component';
import { HeaderComponent } from '../../../core/widgets/header/header.component';
import { ProductsService } from '../../data-access/products.service';
import { Product } from '../../util/product.model';
import { LanguageService } from '../../../core/services/language-service/language.service';
import { ApiTranslationPipe } from '../../../core/pipes/api-translation.pipe';
import { NgxIkImageCdnComponent } from '../../../shared/ngx-ik-image-cdn/ngx-ik-image-cdn.component';
import { environment } from '../../../../environments/environment';
import { NgModel } from '@angular/forms';

declare var TamaraWidgetV2: any;
declare var TabbyPromo: any;

declare global {
  interface Window {
    tamaraWidgetConfig: any;
  }
}

// Extend the Window interface to include TamaraWidgetV2
declare global {
  interface Window {
    TamaraWidgetV2: any;
  }
}

@Component({
  selector: 'app-product-view',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    NgStyle,
    TranslateModule,
    RouterLink,
    NgFor,
    SkeletonModule,
    FooterComponent,
    HeaderComponent,
    ButtonModule,
    RippleModule,
    ToastModule,
    ApiTranslationPipe,
    NgxIkImageCdnComponent,
    GalleriaModule,
  ],
  templateUrl: './product-view.component.html',
  styleUrl: './product-view.component.scss',
  providers: [MessageService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProductViewComponent implements OnInit, OnDestroy {
  @Input('id') productId!: number;

  service = inject(ProductsService);
  _cd = inject(ChangeDetectorRef);
  cartService = inject(CartService);
  router = inject(Router);
  messageService = inject(MessageService);
  languageService = inject(LanguageService);
  elementRef = inject(ElementRef);
  renderer = inject(Renderer2);

  product$: Observable<Product | undefined> | undefined;
  productPrice!: number;
  displayBasic: boolean = false;
  activeImage: number = 0;
  images: any[] = [];
  baseStorage = environment.baseStorage;

  responsiveOptions: any[] = [
    {
      breakpoint: '1500px',
      numVisible: 5,
    },
    {
      breakpoint: '1024px',
      numVisible: 3,
    },
    {
      breakpoint: '768px',
      numVisible: 2,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];

  ngOnInit() {
    window.scrollTo(0, 0);
    this.fetchProduct();
  }

  ngOnDestroy(): void {
    const existingScript = this.elementRef.nativeElement.querySelector(
      'script#tabby-script'
    );
    if (existingScript) {
      existingScript.remove();
    }
  }

  fetchProduct() {
    const { data$ } = this.service.getAllProducts();
    this.product$ = data$.pipe(
      map((products) => {
        window.tamaraWidgetConfig = {
          lang: this.languageService.getLanguage(),
          country: 'SA',
          publicKey: environment.tamaraPublicKey,
          css: '.tamara-summary-widget--inline-outlined:hover {background-color: #272727 !important;}',
        };

        let product = products.find(
          (product) => product.localProduct.product_id == this.productId
        );
        if (product) {
          if (!product?.localProduct.images?.length) {
            product.localProduct.images = ['default-image.jpg'];
          }
          while (product && product.localProduct.images.length < 4) {
            product.localProduct.images.push(product.localProduct.images[0]);
          }
        }
        window.TamaraWidgetV2.refresh();
        this.loadTabby(product?.Price ?? 0);
        this.productPrice = product?.Price ?? 0;
        return product;
      })
    );
  }

  addToCart(product: Product) {
    const isItemAdded: boolean = this.cartService.addItem(product);
    this.messageService.add({
      key: 'confirm',
      severity: isItemAdded ? 'success' : 'error',
      summary: this.languageService.translate(
        isItemAdded ? 'products.toast.added' : 'products.toast.cart_add_error'
      ),
    });
  }

  onConfirm() {
    this.router.navigate(['/cart']);
  }

  loadTabby(amount: number) {
    if (typeof TabbyPromo === 'undefined') {
      const script = document.createElement('script');
      script.src = 'https://checkout.tabby.ai/tabby-promo.js';
      script.id = 'tabby-script';
      script.onload = () => {
        this.initTabby(amount);
      };
      this.elementRef.nativeElement.appendChild(script);
    } else {
      this.initTabby(amount);
    }
  }

  initTabby(amount: number) {
    const tabbyCard = new TabbyPromo({
      selector: '#tabbyCard', // empty div for TabbyPromo.
      publicKey: environment.tabbyPublicKey,
      installmentsCount: 4,
      merchantCode: environment.merchantCode,
      currency: 'SAR', // required, currency of your product. AED|SAR|KWD|BHD|QAR only supported, with no spaces or lowercase.
      lang: this.languageService.getLanguage(), // Optional, language of snippet and popups.
      price: amount,
      size: 'wide', // required, can be also 'wide', depending on the width.
      theme: 'green', // required, can be also 'default'.
      header: true, // if a Payment method name present already.
    });

    const tamaraWidget = this.renderer.createElement('tamara-widget');
    this.renderer.setAttribute(tamaraWidget, 'type', 'tamara-summary');
    this.renderer.setAttribute(tamaraWidget, 'amount', amount.toString());
    this.renderer.setAttribute(tamaraWidget, 'inline-type', '2');
    this.renderer.setAttribute(tamaraWidget, 'inline-variant', 'outlined');

    const container = this.elementRef.nativeElement.querySelector(
      '#tamaraWidgetContainer'
    );
    if (container) {
      // Clear all existing children
      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }

      // Append the new widget
      this.renderer.appendChild(container, tamaraWidget);
    }
    this._cd.markForCheck();
    this._cd.detectChanges();
  }
}
