import { Injectable, inject } from '@angular/core';
import { first, map } from 'rxjs';
import { CustomHttpClientService } from '../../services/custom-http-client.service';
import { FooterItem } from './footer-item.model';
import { footerApi } from './footer.api';
import { CacheService } from '../../services/cache.service';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  private customHttp = inject(CustomHttpClientService);
  private cacheService = inject(CacheService);

  private readonly CACHE_KEYS = {
    FOOTER: 'cached_footer',
  };

  getFooterInfo() {
    return this.cacheService.getCachedData(this.CACHE_KEYS.FOOTER, () =>
      this.customHttp.get<FooterItem[]>(footerApi.getFooterInfo).pipe(
        first(),
        map((res) => {
          for (let i = 0; i < res?.length; i++) {
            const t2 = new FooterItem();
            Object.assign(t2, res[i]);
            res[i] = t2;
          }
          return res;
        })
      )
    );
  }
}
