import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

interface TransactionResponse {
  data: {
    Receipt: string;
    TransactionId: string;
    TransactionOTP: string;
  };
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class DemoService {
  private apiUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  login() {
    return this.http.post(`${this.apiUrl}/demo/login`, {});
  }

  createTransaction(productId: string) {
    return this.http.post<TransactionResponse>(
      `${this.apiUrl}/demo/createTransaction`,
      {
        productId: productId,
      }
    );
  }
}
