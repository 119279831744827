import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { inject } from '@angular/core';
import { CacheService } from '../services/cache.service';

export class AuthInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(environment.jwtKey);
    const router = inject(Router);
    const cacheService = inject(CacheService);
    if (token) {
      const clonedReq = req.clone({
        setHeaders: {
          'Custom-Authorization': token,
        },
      });
      return next.handle(clonedReq).pipe(
        tap(
          () => {},
          (error) => {
            if (error.status === 401) {
              localStorage.removeItem(environment.jwtKey);
              cacheService.clearCache('cached_cards');
              cacheService.clearCache('cached_orders');
              router.navigate(['/login']);
            }
          }
        )
      );
    } else {
      return next.handle(req);
    }
  }
}
