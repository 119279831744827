import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, finalize } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private cache: { [key: string]: BehaviorSubject<any> } = {};

  /**
   * Gets cached data and updates it in the background
   * @param key Cache key
   * @param fetchFn Function to fetch fresh data
   * @returns Observable of cached/fresh data
   */
  getCachedData<T>(
    key: string,
    fetchFn: () => Observable<T>
  ): { data$: Observable<T>; loading: boolean } {
    // Create subject if it doesn't exist
    this.cache[key] = new BehaviorSubject<T | null>(null);

    // Try to get cached data
    const cachedData = localStorage.getItem(key);
    const loading = !cachedData;

    if (cachedData) {
      this.cache[key].next(JSON.parse(cachedData));
    }

    // Fetch fresh data in background
    fetchFn()
      .pipe(
        finalize(() => {
          // Ensure the subject completes after emitting the value
          this.cache[key].complete();
        })
      )
      .subscribe({
        next: (freshData) => {
          localStorage.setItem(key, JSON.stringify(freshData));
          this.cache[key].next(freshData);
        },
        error: (err) => {
          this.cache[key].error(err);
        },
      });

    return {
      data$: this.cache[key].asObservable(),
      loading,
    };
  }

  clearCache(key?: string) {
    if (key) {
      localStorage.removeItem(key);
      if (this.cache[key]) {
        this.cache[key].complete();
        delete this.cache[key];
      }
    } else {
      localStorage.clear();
      Object.keys(this.cache).forEach((k) => {
        this.cache[k].complete();
      });
      this.cache = {};
    }
  }
}
