<div class="content">
  <div class="content-inner">
    <div class="container">
      <div class="section">
        <!-- Two-column layout container -->
        <div class="cart-layout">
          <!-- Left column: Order Summary -->
          <div class="order-summary">
            <h2>
              {{ "booking.cart.summary_order" | translate }} ({{ cart.length }})
            </h2>

            <!-- Cart Items -->
            <div class="cart-items">
              <ng-container *ngFor="let product of cart; index as i">
                <app-cart-item
                  [product]="product"
                  [index]="i"
                  [isError]="i === errorIndex"
                  [ownCards]="ownCards[product.SiteId] || []"
                  [initialCardNumber]="
                    cardNumberInput[product.branch.siteId.toString()]
                  "
                  [cardValidityStatus]="
                    cardNumberStatus[product.branch.siteId.toString()]
                  "
                  [cardOwnerName]="
                    cardNumberName[product.branch.siteId.toString()]
                  "
                  (remove)="removeItem(product.ProductId, i)"
                  (increment)="incrementItem(product.ProductId)"
                  (decrement)="decrementItem(product.ProductId)"
                  (cardChange)="
                    onCardNumberChange(product.branch.siteId.toString(), $event)
                  "
                  (selectSavedCard)="
                    onCardNumberChange(product.branch.siteId.toString(), $event)
                  "
                ></app-cart-item>
              </ng-container>
            </div>
          </div>

          <!-- Right column: Payment Details -->
          <div class="payment-details">
            <h2>{{ "booking.cart.payment_details" | translate }}</h2>

            <!-- User Information Form -->
            <form [formGroup]="userForm" (ngSubmit)="checkout()">
              <div
                class="loading-wrapper"
                *ngIf="isAllLoading && !redirectingUrl"
              >
                <i class="pi pi-spin pi-spinner"></i>
              </div>
              <div class="loading-wrapper" *ngIf="redirectingUrl">
                <div class="loading-redirect">
                  <i class="pi pi-spin pi-spinner"></i>
                  <p>{{ "booking.cart.redirect.redirecting" | translate }}</p>
                  <p *ngIf="counter > 0">
                    {{
                      "booking.cart.redirect.redirect_countdown"
                        | translate : { counter: counter }
                    }}
                  </p>
                  <p *ngIf="counter <= 0">
                    {{ "booking.cart.redirect.redirect_manual" | translate }}
                    <a [href]="redirectingUrl">{{
                      "general.action.click_here" | translate
                    }}</a
                    >.
                  </p>
                </div>
              </div>
              <div class="form-section mb-4">
                <h3>
                  {{ "booking.checkout.personal_details" | apiTranslation }}
                </h3>
                <div class="form-group">
                  <div class="form-control">
                    <input
                      id="firstName"
                      formControlName="firstName"
                      autocomplete="first-name"
                      type="text"
                      required
                      [placeholder]="'general.form.first_name' | translate"
                      [ngClass]="{
                        invalid: isValidInput('firstName', true)
                      }"
                    />
                    <label
                      class="error-label"
                      for="firstName"
                      *ngIf="isValidInput('firstName', true)"
                    >
                      <i class="pi pi-exclamation-circle"></i>
                      {{ "form_validation.errors.required_field" | translate }}
                    </label>
                  </div>
                  <div class="form-control">
                    <input
                      id="lastName"
                      formControlName="lastName"
                      autocomplete="last-name"
                      type="text"
                      required
                      [placeholder]="'general.form.last_name' | translate"
                      [ngClass]="{
                        invalid: isValidInput('lastName', true)
                      }"
                    />
                    <label
                      class="error-label"
                      for="lastName"
                      *ngIf="isValidInput('lastName', true)"
                    >
                      <i class="pi pi-exclamation-circle"></i>
                      {{ "form_validation.errors.required_field" | translate }}
                    </label>
                  </div>
                  <div class="form-control">
                    <input
                      id="email"
                      formControlName="email"
                      autocomplete="email"
                      type="email"
                      required
                      [placeholder]="'general.form.email' | translate"
                      [ngClass]="{
                        invalid: isValidInput('email', true)
                      }"
                    />
                    <label
                      class="error-label"
                      for="email"
                      *ngIf="isValidInput('email', true)"
                    >
                      <i class="pi pi-exclamation-circle"></i>
                      {{
                        (isValidEmail
                          ? "form_validation.errors.email"
                          : "form_validation.errors.email_validity"
                        ) | translate
                      }}
                    </label>
                  </div>
                  <div class="form-control">
                    <ngx-lib-phone-input
                      dir="ltr"
                      formControlName="phoneNumber"
                    ></ngx-lib-phone-input>
                    <label
                      class="error-label"
                      for="phoneNumber"
                      *ngIf="!isValidInput('phoneNumber', true)"
                    >
                      <i class="pi pi-exclamation-circle"></i>
                      {{ "form_validation.errors.phone" | translate }}
                    </label>
                  </div>
                </div>
              </div>

              <!-- Payment Methods -->
              <div class="form-section mt-5">
                <h3>
                  {{ "booking.checkout.payment_details" | apiTranslation }}
                </h3>
                <div class="payment-methods">
                  <label
                    [ngClass]="{ active: selectedPaymentMethod == 'visaCard' }"
                  >
                    <input
                      type="radio"
                      name="paymentMethod"
                      class="ms-1 me-1"
                      (click)="selectPaymentMethod('visaCard')"
                      [checked]="selectedPaymentMethod == 'visaCard'"
                    />
                    <img
                      height="15"
                      src="assets/images/Apple_Pay.png"
                      alt="mada-card-payment-method"
                    />
                    <img
                      height="15"
                      src="assets/images/master-card.png"
                      alt="master-card-payment-method"
                    />
                    {{ "booking.cart.checkout.card" | apiTranslation }}
                  </label>
                  <div class="divider-wrapper">
                    <div class="divider-line"></div>
                    <span>{{ "events.or" | translate }}</span>
                    <div class="divider-line"></div>
                  </div>
                  <p class="text-center w-full">
                    {{ "booking.cart.checkout.tabby" | apiTranslation }}
                  </p>
                  <label
                    *ngIf="!isLoading && !isTabbyLoading"
                    class="d-flex aic tabby-radio mx-2"
                    [ngClass]="{
                      'disabled-contrast': !isTabbyAllowed,
                      active: selectedPaymentMethod == 'tabby'
                    }"
                  >
                    <input
                      type="radio"
                      name="paymentMethod"
                      class="ms-1 me-6"
                      [disabled]="!isTabbyAllowed"
                      (click)="selectPaymentMethod('tabby')"
                      [checked]="selectedPaymentMethod == 'tabby'"
                    />
                    <app-ngx-ik-image-cdn
                      path="images/tabby-new.webp"
                    ></app-ngx-ik-image-cdn>
                  </label>
                  <label
                    *ngIf="!isLoading"
                    class="d-flex aic tabby-radio mx-2"
                    [ngClass]="{
                      'disabled-contrast': !isTabbyAllowed,
                      active: selectedPaymentMethod == 'tamara'
                    }"
                  >
                    <input
                      type="radio"
                      name="paymentMethod"
                      class="ms-1 me-6"
                      (click)="selectPaymentMethod('tamara')"
                      [checked]="selectedPaymentMethod == 'tamara'"
                    />
                    <img height="17" src="assets/images/tamara.png" alt="" />
                  </label>
                  <div
                    *ngIf="isLoading || isTabbyLoading"
                    class="skeleton-loader"
                  >
                    <div class="skeleton-item"></div>
                  </div>
                </div>

                <div
                  class="mb-3"
                  id="tabbyCard"
                  [hidden]="
                    isLoading ||
                    isTabbyLoading ||
                    selectedPaymentMethod != 'tabby'
                  "
                ></div>

                <div
                  class="mb-3 mw-72"
                  *ngIf="
                    !isLoading &&
                    !isTamaraLoading &&
                    selectedPaymentMethod == 'tamara'
                  "
                >
                  <tamara-widget
                    type="tamara-summary"
                    amount="{{
                      totalTransactionAmount('TransactionAmount')
                        | number : '1.2-2'
                    }}"
                    inline-type="6"
                    inline-variant="outlined"
                    config='{"badgePosition":"","showExtraContent":"full","hidePayInX":false}'
                  ></tamara-widget>
                </div>

                <!-- Promo Code -->
                <div class="promo-code">
                  <h3>{{ "booking.checkout.promo_code" | apiTranslation }}</h3>
                  <div class="promo-input">
                    <input
                      type="text"
                      [placeholder]="
                        'booking.checkout.promo_code' | apiTranslation
                      "
                      [(ngModel)]="coupon"
                      appAllowNumbersAndLetters
                      [ngModelOptions]="{ standalone: true }"
                      [ngClass]="{
                        valid: validCoupon == true,
                        invalid: validCoupon == false
                      }"
                    />
                    <button
                      type="button"
                      (click)="applyCoupon()"
                      [disabled]="isCouponLoading"
                    >
                      <ng-container *ngIf="!isCouponLoading; else loading">
                        {{ "booking.checkout.promo_button" | apiTranslation }}
                      </ng-container>
                      <ng-template #loading>
                        <i class="pi pi-spin pi-spinner text-0"></i>
                      </ng-template>
                    </button>
                  </div>
                  <label class="error-label" *ngIf="validCoupon == false">
                    <i class="pi pi-exclamation-circle"></i>
                    {{ "form_validation.errors.invalid_coupon" | translate }}
                  </label>
                </div>

                <ng-container *ngIf="transaction$ | async as ts"></ng-container>

                <ng-container *ngIf="!isLoading; else loadingTransaction">
                  <ng-container *ngIf="transactions; else errorTransaction">
                    <div class="bill">
                      <h3>{{ "booking.cart.price_details" | translate }}</h3>
                      <div
                        class="bill-item"
                        *ngFor="let product of flattenTransactionLines()"
                      >
                        <div class="product-name">
                          <p class="desc">{{ product["ProductName"] }}</p>
                          <span
                            class="item-count-span"
                            *ngIf="product['quantity'] > 1"
                          >
                            <span>x </span>
                            <span>{{ product["quantity"] }}</span>
                          </span>
                        </div>

                        <p class="price">
                          {{ product["Price"] | number : "1.2-2" }}
                          SAR
                        </p>
                      </div>
                      <div class="bill-item">
                        <p class="desc">
                          <!-- {{
                            transactions[0].TransactionLinesDTOList[0][
                              "taxName"
                            ]
                          }} -->
                          15% {{ "booking.cart.vat" | apiTranslation }}
                        </p>
                        <p class="price">
                          {{
                            totalTransactionAmount("TaxAmount")
                              | number : "1.2-2"
                          }}
                          SAR
                        </p>
                      </div>
                      <div class="bill-divider"></div>
                      <div class="bill-item">
                        <p class="desc">
                          {{ "booking.cart.subtotal_price" | apiTranslation }}
                        </p>
                        <p class="price">
                          {{
                            totalTransactionAmount("TransactionAmount")
                              | number : "1.2-2"
                          }}
                          SAR
                        </p>
                      </div>
                      <div class="bill-item">
                        <p class="desc">
                          {{ "booking.cart.discount" | apiTranslation }}
                        </p>
                        <p class="price">
                          -{{
                            totalTransactionAmount("TransactionDiscountAmount")
                              | number : "1.2-2"
                          }}
                          SAR
                        </p>
                      </div>
                      <div class="bill-divider"></div>
                      <div class="bill-item">
                        <h3>
                          {{ "booking.cart.total_price" | apiTranslation }}
                        </h3>
                        <h3 class="total-price">
                          {{
                            totalTransactionAmount("TransactionNetAmount")
                              | number : "1.2-2"
                          }}
                          SAR
                        </h3>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #errorTransaction>
                    <p>{{ "booking.cart.unexpected_error" | translate }}</p>
                  </ng-template>
                </ng-container>
                <ng-template #loadingTransaction>
                  <ng-container *ngFor="let _ of [].constructor(5)">
                    <div class="ticket-skeleton mt-1">
                      <p-skeleton
                        height="2rem"
                        borderRadius="10px"
                      ></p-skeleton>
                    </div>
                  </ng-container>
                </ng-template>

                <!-- Terms and Conditions -->
                <div class="terms-section">
                  <div class="terms-check">
                    <input
                      id="termsCheck"
                      formControlName="termsCheck"
                      name="termsCheck"
                      type="checkbox"
                    />
                    <label for="termsCheck">
                      {{
                        "booking.checkout.details.payment_details.terms_check_1"
                          | translate
                      }}
                      <a href="content/termsAndConditions">
                        {{
                          "booking.checkout.details.payment_details.terms_check_2"
                            | translate
                        }}
                      </a>
                    </label>
                  </div>
                  <label
                    class="error-label"
                    for="termsCheck"
                    *ngIf="isValidInput('termsCheck', true)"
                  >
                    <i class="pi pi-exclamation-circle"></i>
                    {{ "form_validation.errors.termsCheck_field" | translate }}
                  </label>
                </div>

                <!-- Checkout Button -->
                <div
                  class="checkout-button"
                  *ngIf="selectedPaymentMethod == 'visaCard'"
                >
                  <button
                    type="submit"
                    [disabled]="!cart.length || checkCardLoading"
                  >
                    <ng-container
                      *ngIf="!checkCardLoading && !isLoading; else loadingCard"
                    >
                      {{ "booking.cart.pay" | translate }}
                      {{
                        totalTransactionAmount("TransactionNetAmount")
                          | number : "1.2-2"
                      }}
                      SAR
                    </ng-container>
                    <ng-template #loadingCard>
                      <i class="pi pi-spin pi-spinner text-0"></i>
                    </ng-template>
                  </button>
                </div>

                <div
                  class="checkout-button"
                  *ngIf="
                    (!isLoading && selectedPaymentMethod == 'tabby') ||
                    selectedPaymentMethod == 'tamara'
                  "
                >
                  <button
                    type="button"
                    class="{{ selectedPaymentMethod }}"
                    (click)="
                      selectedPaymentMethod == 'tabby'
                        ? tabbyCheckout()
                        : tamaraCheckout()
                    "
                    [disabled]="isLoading"
                  >
                    <ng-container *ngIf="!isLoading; else loading">
                      {{ "booking.cart.pay" | translate }}
                      {{
                        totalTransactionAmount("TransactionNetAmount") / 4
                          | number : "1.2-2"
                      }}
                      SAR
                    </ng-container>
                    <ng-template #loading>
                      <i class="pi pi-spin pi-spinner"></i>
                    </ng-template>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="section">
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</div>

<p-toast [position]="'top-center'"></p-toast>
