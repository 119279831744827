import { Routes } from '@angular/router';
import { CartComponent } from './booking/feature/cart/cart.component';
import { CheckoutComponent } from './booking/feature/checkout/checkout.component';
import { ThankyouComponent } from './booking/feature/thankyou/thankyou.component';
import { BranchPageComponent } from './branch/feature/branch-page/branch-page.component';
import { ContentViewerComponent } from './content-viewer/feature/content-viewer.component';
import { EventFormComponent } from './events/event-form/event-form.component';
import { ProductViewComponent } from './products/feature/product-view/product-view.component';
import { ProductsPageComponent } from './products/feature/products-page/products-page.component';
import { SignInComponent } from './user/feature/sign-in/sign-in.component';
import { SignUpComponent } from './user/feature/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './user/feature/forgot-password/forgot-password.component';
import { authGuard } from './core/guards/auth.guard';
import { ProfileComponent } from './user/feature/profile/profile.component';
import { CardsComponent } from './user/feature/cards/cards.component';
import { UserDashboardComponent } from './user/feature/user-dashboard/user-dashboard.component';
import { OrdersComponent } from './user/feature/orders/orders.component';
import { DemoComponent } from './demo/demo.component';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ProductsPageComponent,
      },
      {
        path: 'view/:id',
        component: ProductViewComponent,
      },
      {
        path: 'branch/:id',
        component: BranchPageComponent,
      },
    ],
  },
  {
    path: 'cart',
    component: CartComponent,
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
  },
  {
    path: 'thankyou',
    component: ThankyouComponent,
  },
  {
    path: 'events/:id',
    component: EventFormComponent,
  },
  {
    path: 'content/:slug',
    component: ContentViewerComponent,
  },
  {
    path: 'register',
    component: SignUpComponent,
  },
  {
    path: 'login',
    component: SignInComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'dashboard',
    component: UserDashboardComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'cards',
        component: CardsComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'orders',
        component: OrdersComponent,
      },
      {
        path: '',
        redirectTo: 'cards',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'demo',
    component: DemoComponent,
  },
];
