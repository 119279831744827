import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { BadgeModule } from 'primeng/badge';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { CartService } from '../../../booking/data-access/cart.service';
import { LanguageService } from '../../services/language-service/language.service';
import { ApiTranslationPipe } from '../../pipes/api-translation.pipe';
import { NgxIkImageCdnComponent } from '../../../shared/ngx-ik-image-cdn/ngx-ik-image-cdn.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../../user/data-access/profile.service';
import { User } from '../../../user/util/user.model';
import { environment } from '../../../../environments/environment';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../../../user/data-access/auth.service';
import { UserStateService } from '../../../user/data-access/user-state.service';
import { CacheService } from '../../services/cache.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterLink,
    NgClass,
    NgStyle,
    NgIf,
    ButtonModule,
    BadgeModule,
    AsyncPipe,
    RippleModule,
    ApiTranslationPipe,
    NgxIkImageCdnComponent,
    TranslateModule,
    MenuModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  @Input() styleClass: string = '';
  @Input() position: 'fixed' | 'sticky' = 'sticky';

  @ViewChild('header') header: ElementRef | undefined;

  isHamburgerMenuOpen = false;
  langageService = inject(LanguageService);
  profileService = inject(ProfileService);
  authService = inject(AuthService);
  translateService = inject(TranslateService);
  router = inject(Router);
  userState = inject(UserStateService);
  cacheService = inject(CacheService);

  cartLength$ = inject(CartService).cartLength$;

  isLoggedIn: boolean = false;
  user!: User | null;
  userAvatar!: string;
  userDisplayedName!: string;
  items: MenuItem[] | undefined;

  ngOnInit(): void {
    this.items = [
      {
        label: this.translateService.instant('navbar.cards'),
        items: [
          {
            label: this.translateService.instant('navbar.all_cards'),
            icon: 'pi pi-credit-card',
            command: () => {
              this.router.navigate(['/dashboard/cards']);
            },
          },
          {
            label: this.translateService.instant('profile.orders'),
            icon: 'pi pi-shopping-cart',
            command: () => {
              this.router.navigate(['/dashboard/orders']);
            },
          },
        ],
      },
      {
        label: this.translateService.instant('navbar.profile'),
        items: [
          {
            label: this.translateService.instant('navbar.profile_info'),
            icon: 'pi pi-cog',
            command: () => {
              this.router.navigate(['/dashboard/profile']);
            },
          },
          {
            label: this.translateService.instant('navbar.logout'),
            icon: 'pi pi-sign-out',
            command: () => {
              this.authService.logout().subscribe();
              localStorage.removeItem(environment.jwtKey);
              this.cacheService.clearCache('cached_cards');
              this.cacheService.clearCache('cached_orders');
              this.userState.setUser(null);
              this.isLoggedIn = false;
              this.user = null;
              this.router.navigate(['/']);
            },
          },
        ],
      },
    ];

    if (!localStorage.getItem(environment.jwtKey)) {
      return;
    }

    this.isLoggedIn = false;

    this.userState.user$.subscribe((user) => {
      if (user) {
        this.user = user;
        this.userAvatar = environment.baseStorage + '/' + user.avatar;
        this.userDisplayedName = user.name.split(' ')[0];
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
        this.user = null;
      }
    });

    // Try to get from localStorage first
    const storedUser = localStorage.getItem('currentUser');
    if (storedUser) {
      this.userState.setUser(JSON.parse(storedUser));
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    let headerElement: HTMLElement | undefined = this.header?.nativeElement;
    // let top = headerElement?.getBoundingClientRect().top;
    if (window.scrollY > 100) {
      headerElement?.classList.add('active');
    } else {
      headerElement?.classList.remove('active');
    }
  }

  toggleLanguage() {
    const lang = this.langageService.getLanguage();
    if (lang === 'en') {
      this.langageService.setLanguage('ar');
    } else if (lang === 'ar') {
      this.langageService.setLanguage('en');
    }
  }

  toggleHamburgerMenu(): void {
    this.isHamburgerMenuOpen = !this.isHamburgerMenuOpen;
  }
}
