import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { Router, RouterModule } from '@angular/router';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SkeletonModule } from 'primeng/skeleton';
import { Observable, finalize, map, shareReplay, tap, take } from 'rxjs';
import { FooterComponent } from '../../../core/widgets/footer/footer.component';
import { HeaderComponent } from '../../../core/widgets/header/header.component';
import { ProductsService } from '../../../products/data-access/products.service';
import { ProductCardComponent } from '../../../products/ui/product-card/product-card.component';
import { Product } from '../../../products/util/product.model';
import { Branch } from '../../util/branch.model';
import { ApiTranslationPipe } from '../../../core/pipes/api-translation.pipe';
import { LanguageService } from '../../../core/services/language-service/language.service';
import { NgxIkImageCdnComponent } from '../../../shared/ngx-ik-image-cdn/ngx-ik-image-cdn.component';
import { FacebookPixelEventTrackerService } from '../../../core/services/facebook-pixel-event-tracker.service';
import { GoogleEventTrackerService } from '../../../core/services/google-event-tracker.service';
import { SnapEventTrackerService } from '../../../core/services/snap-event-tracker.service';
import { TiktokEventTrackerService } from '../../../core/services/tiktok-event-tracker.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-branch-page',
  standalone: true,
  imports: [
    NgFor,
    AsyncPipe,
    NgIf,
    GoogleMap,
    MapMarker,
    DropdownModule,
    NgStyle,
    OverlayPanelModule,
    CalendarModule,
    FormsModule,
    SkeletonModule,
    NgClass,
    RouterModule,
    FooterComponent,
    HeaderComponent,
    ProductCardComponent,
    ApiTranslationPipe,
    NgxIkImageCdnComponent,
  ],
  templateUrl: './branch-page.component.html',
  styleUrl: './branch-page.component.scss',
})
export class BranchPageComponent implements OnInit, OnDestroy {
  @Input('id') branchId!: number;

  @ViewChild('scrollContainer') scrollContainer:
    | ElementRef<HTMLDivElement>
    | undefined;

  mapOptions: google.maps.MapOptions = {};

  markerOptions: google.maps.MarkerOptions = { draggable: false };
  markerPosition: google.maps.LatLngLiteral | google.maps.LatLng = {
    lat: 0,
    lng: 0,
  };

  router = inject(Router);
  service = inject(ProductsService);
  cdRef = inject(ChangeDetectorRef);
  languageService = inject(LanguageService);

  branches$: Observable<Branch[]> | undefined;
  branch$: Observable<Branch> | undefined;
  banners$: Observable<string[] | undefined> | undefined;
  products$: Observable<Product[] | undefined> | undefined;

  currentBranch!: Branch;
  productsLoading = false;
  bannersLoading = false;
  filter: any = {
    date: new Date(),
    age: { from: 0, to: 100 },
  };
  activeBannerIndex = 0;
  hoveredProduct: number = -1;
  scrollOverflow: boolean = false;
  interval: any;
  baseStorage = environment.baseStorage;

  @ViewChild('branchSlider') branchSlider:
    | ElementRef<HTMLDivElement>
    | undefined;

  constructor() {
    window.addEventListener('scroll', () => {
      let headers = document.querySelectorAll('.header-container');
      headers.forEach((header) => {
        // let top = header?.getBoundingClientRect().top;
        if (window.scrollY > 50) {
          header?.classList.add('active');
        } else {
          header?.classList.remove('active');
        }
      });
    });
  }

  ngOnInit(): void {
    const { data$ } = this.service.getBranches();
    this.branches$ = data$.pipe(
      map((branches) => branches),
      tap((branches) => {
        this.updateCurrentBranch(branches);
      })
    );

    this.interval = setInterval(() => {
      this.goToBanner(
        this.activeBannerIndex < (this.currentBranch.images?.length ?? 0) - 1
          ? this.activeBannerIndex + 1
          : 0
      );
      this.cdRef.markForCheck();
    }, 4000);

    this.initiatePage();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  initiatePage() {
    this.branch$ = this.service.getProducts(this.branchId).pipe(
      tap((branch) => {
        this.cdRef.markForCheck();
        if (branch.location) {
          this.markerPosition = branch.location;
          this.mapOptions = {
            center: this.markerPosition,
            zoom: 4,
          };
        }
      }),
      shareReplay(1)
    );
    this.fetchBanners();
    this.fetchProducts();
  }

  onBranchChange(event: any) {
    this.goToBranch(event.value.siteId);
  }

  goToBranch(branchId: number) {
    if (branchId == -1) {
      this.router.navigate(['/']);
      return;
    }
    this.branchId = branchId;
    this.branches$?.pipe(take(1)).subscribe((branches) => {
      this.updateCurrentBranch(branches);
    });
    this.router.navigate(['branch', branchId]);
    this.initiatePage();
  }

  fetchProducts() {
    this.productsLoading = true;
    this.products$ = this.branch$
      ?.pipe(map((branch) => branch.products))

      .pipe(
        finalize(() => {
          this.productsLoading = false;
          const container = this.scrollContainer?.nativeElement;
          this.cdRef.detectChanges();
          if (container) {
            this.scrollOverflow = container.scrollWidth > container.clientWidth;
          }
        })
      );
  }
  fetchBanners() {
    this.bannersLoading = true;
    this.banners$ = this.branch$
      ?.pipe(map((branch) => branch.images ?? ['default-image.jpg']))

      .pipe(
        finalize(() => {
          this.bannersLoading = false;
        })
      );
  }

  goToBanner(index: number) {
    let carousel = document.querySelector('.carousel');
    if (carousel) {
      const carouselWidth = carousel.clientWidth;
      carousel.scrollLeft =
        carouselWidth *
        index *
        (document.documentElement.dir == 'rtl' ? -1 : 1); //-1 for rtl
      this.activeBannerIndex = index;
    }
  }

  scrollBranchSlider(direction: 'left' | 'right') {
    const slider = this.branchSlider?.nativeElement;
    if (slider) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      slider.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  }

  convertColorWithOpacity(color: string, opacity: number): string {
    if (color.startsWith('#')) {
      let r = parseInt(color.slice(1, 3), 16);
      let g = parseInt(color.slice(3, 5), 16);
      let b = parseInt(color.slice(5, 7), 16);
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    } else if (color.startsWith('rgb')) {
      return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
    }
    return color;
  }

  private updateCurrentBranch(branches: Branch[]) {
    this.currentBranch = branches.find(
      (branch) => branch.siteId === this.branchId
    ) as Branch;

    if (this.currentBranch) {
      GoogleEventTrackerService.trackEvent('view_branch', {
        branch: this.currentBranch,
      });
      FacebookPixelEventTrackerService.trackEvent('view_branch', {
        branch: this.currentBranch,
      });
      SnapEventTrackerService.trackEvent('VIEW_CONTENT', {
        item_category: this.currentBranch.name,
      });
      TiktokEventTrackerService.trackEvent('ViewContent', {
        contents: [
          {
            content_id: `${this.currentBranch.siteId}`,
            content_type: 'product_group',
            content_name: this.currentBranch.name,
          },
        ],
      });
    }
  }
}
